// src/components/timeline/ZoomControls.js

import React from 'react';
import styled from 'styled-components';
import { FaPlus, FaMinus, FaCompress } from 'react-icons/fa';

const ControlsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 20;

  & > button {
    background-color: white;
    border: 1px solid #ccc;
    padding: 6px;
    margin-bottom: 4px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #eee;
    }
  }
`;

const ZoomControls = ({ onZoomIn, onZoomOut, onReset }) => {
  return (
    <ControlsContainer>
      <button onClick={onZoomIn} title="Zoom In">
        <FaPlus />
      </button>
      <button onClick={onZoomOut} title="Zoom Out">
        <FaMinus />
      </button>
      <button onClick={onReset} title="Reset Zoom">
        <FaCompress />
      </button>
    </ControlsContainer>
  );
};

export default ZoomControls;