// src/components/timeline/TimeMarker.js

import React from 'react';
import styled from 'styled-components';

const Marker = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #e0e0e0;
`;

const MarkerLabel = styled.div`
  position: absolute;
  top: 0;
  font-size: 12px;
  color: #555;
  transform: translateX(-50%);
`;

const TimeMarker = ({ time, position }) => {
  return (
    <Marker style={{ left: `${position}%` }}>
      <MarkerLabel style={{ top: '0' }}>{time}</MarkerLabel>
    </Marker>
  );
};

export default TimeMarker;