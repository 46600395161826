// src/components/timeline/TimelineItem.js

import React from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import {ContextMenu, MenuItem, ContextMenuTrigger} from 'react-contextmenu';
import {FaEdit, FaTrash} from 'react-icons/fa';
import {Tooltip as ReactTooltip} from 'react-tooltip';

const Item = styled.div`
    position: absolute;
    height: 40px;
    background-color: ${props => props.bgColor || '#2196F3'};
    border-radius: 4px;
    cursor: grab;
    display: flex;
    align-items: center;
    padding: 0 8px;
    color: white;
    user-select: none;
    box-sizing: border-box;
    border: ${props => (props.isSelected ? '2px solid #FFD700' : 'none')};
`;

const ContextMenuStyled = styled(ContextMenu)`
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 0;
    border-radius: 4px;
    z-index: 1000;

    & > div {
        padding: 8px 20px;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }
`;

const TimelineItem = ({item, timelineWidth, transformScale, onDrag, isSelected, onSelect, onEdit, onDelete}) => {
    const handleDragStop = (e, data) => {
        const newStart = (data.x / (timelineWidth * transformScale)) * 300; // Assuming TOTAL_DURATION is 300
        onDrag(newStart, item);
    };

    return (
        <>
            <Draggable
                axis="x"
                bounds="parent"
                onStop={handleDragStop}
                position={{x: (item.start / 300) * timelineWidth * transformScale, y: 0}}
            >
                <ContextMenuTrigger id={`contextmenu-${item.id}`} holdToDisplay={1000}>
                    <Item
                        style={{
                            width: `${(item.duration / 300) * 100}%`
                        }}
                        bgColor={item.bgColor}
                        isSelected={isSelected}
                        onClick={onSelect}
                        data-tip={item.label}
                    >
                        {item.label}
                    </Item>
                </ContextMenuTrigger>
            </Draggable>

            <ContextMenuStyled id={`contextmenu-${item.id}`}>
                <MenuItem onClick={e => onEdit(e, {itemId: item.id, itemLabel: item.label})}>
                    <FaEdit style={{marginRight: '8px'}} /> Edit
                </MenuItem>
                <MenuItem onClick={e => onDelete(e, {itemId: item.id})}>
                    <FaTrash style={{marginRight: '8px'}} /> Delete
                </MenuItem>
            </ContextMenuStyled>

            <ReactTooltip />
        </>
    );
};

export default TimelineItem;
