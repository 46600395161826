// src/components/timeline/VideoTimeline.js

import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {ContextMenu, MenuItem, ContextMenuTrigger} from 'react-contextmenu';
import {FaPlay, FaPause, FaPlus, FaMinus, FaCompress, FaTrash, FaEdit} from 'react-icons/fa';
import ZoomControls from './ZoomControls';
import PlaybackIndicator from './PlaybackIndicator';
import TimelineItem from './TimelineItem';
import TimeMarker from './TimeMarker';

// Styled Components
const TimelineContainer = styled.div`
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
    position: relative;
    overflow: hidden;
    background-color: #f9f9f9;
`;

const PlaybackControls = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 20;

    & > button {
        background-color: white;
        border: 1px solid #ccc;
        padding: 6px 12px;
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #eee;
        }
    }
`;

// Constants
const TOTAL_DURATION = 300; // 300 seconds (5 minutes)
const TIME_MARKER_INTERVAL = 10; // every 10 seconds
const GRID_SNAP_INTERVAL = 5; // snap to every 5 seconds

// Helper Functions
const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

// Sample Data
const initialItems = [
    {
        id: 1,
        type: 'clip',
        start: 20,
        duration: 30,
        label: 'Clip 1',
        bgColor: '#4CAF50'
    },
    {
        id: 2,
        type: 'effect',
        start: 60,
        duration: 15,
        label: 'Effect A',
        bgColor: '#FF9800'
    }
    // Add more items as needed
];

const VideoTimeline = () => {
    const [items, setItems] = useState(initialItems);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [playbackTime, setPlaybackTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [transformScale, setTransformScale] = useState(1);

    const timelineRef = useRef(null);
    const playbackRef = useRef(null);
    const intervalRef = useRef(null);

    // Handle Playback
    useEffect(() => {
        if (isPlaying) {
            intervalRef.current = setInterval(() => {
                setPlaybackTime(prev => {
                    if (prev >= TOTAL_DURATION) {
                        clearInterval(intervalRef.current);
                        setIsPlaying(false);
                        return TOTAL_DURATION;
                    }
                    return prev + 1;
                });
            }, 1000);
        } else if (!isPlaying && intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        return () => clearInterval(intervalRef.current);
    }, [isPlaying]);

    // Handle Drag with Snap-to-Grid
    const handleDrag = (newStart, item) => {
        const clampedStart = Math.max(0, Math.min(newStart, TOTAL_DURATION - item.duration));
        const snappedStart = Math.round(clampedStart / GRID_SNAP_INTERVAL) * GRID_SNAP_INTERVAL;

        setItems(prevItems => prevItems.map(it => (it.id === item.id ? {...it, start: snappedStart} : it)));
    };

    // Handle Context Menu Actions
    const handleAddItem = (e, data) => {
        const timelineWidth = timelineRef.current.clientWidth;
        const rect = timelineRef.current.getBoundingClientRect();
        const clickX = data.mouseX - rect.left;
        const secondsPerPixel = TOTAL_DURATION / timelineWidth / transformScale;
        let start = (clickX / timelineWidth) * TOTAL_DURATION;
        start = Math.round(start / GRID_SNAP_INTERVAL) * GRID_SNAP_INTERVAL;
        start = Math.max(0, Math.min(start, TOTAL_DURATION - 10)); // default duration 10s

        const newItem = {
            id: Date.now(),
            type: 'clip',
            start,
            duration: 10,
            label: `Clip ${items.length + 1}`,
            bgColor: '#2196F3'
        };

        setItems(prevItems => [...prevItems, newItem]);
    };

    const handleDeleteItem = (e, data) => {
        setItems(prevItems => prevItems.filter(it => it.id !== data.itemId));
        setSelectedItemId(null);
    };

    const handleEditItem = (e, data) => {
        const newLabel = prompt('Enter new label:', data.itemLabel);
        if (newLabel) {
            setItems(prevItems => prevItems.map(it => (it.id === data.itemId ? {...it, label: newLabel} : it)));
        }
    };

    // Render Time Markers
    const renderTimeMarkers = () => {
        const markers = [];
        for (let time = 0; time <= TOTAL_DURATION; time += TIME_MARKER_INTERVAL) {
            markers.push(<TimeMarker key={time} time={formatTime(time)} position={(time / TOTAL_DURATION) * 100} />);
        }
        return markers;
    };

    // Render Items
    const renderItems = () => {
        return items.map(item => (
            <TimelineItem
                key={item.id}
                item={item}
                timelineWidth={timelineRef.current?.clientWidth || 800}
                transformScale={transformScale}
                onDrag={handleDrag}
                isSelected={selectedItemId === item.id}
                onSelect={() => setSelectedItemId(item.id)}
                onEdit={handleEditItem}
                onDelete={handleDeleteItem}
            />
        ));
    };

    // Render Playback Indicator
    useEffect(() => {
        if (timelineRef.current) {
            const left = (playbackTime / TOTAL_DURATION) * 100;
            if (playbackRef.current) {
                playbackRef.current.style.left = `${left}%`;
            }
        }
    }, [playbackTime, transformScale]);

    return (
        <TimelineContainer ref={timelineRef}>
            {/* Zoom Controls */}
            <ZoomControls
                onZoomIn={() => setTransformScale(prev => Math.min(prev + 0.5, 5))}
                onZoomOut={() => setTransformScale(prev => Math.max(prev - 0.5, 1))}
                onReset={() => setTransformScale(1)}
            />

            {/* Playback Indicator */}
            <PlaybackIndicator ref={playbackRef} />

            {/* Playback Controls */}
            <PlaybackControls>
                <button onClick={() => setIsPlaying(!isPlaying)}>
                    {isPlaying ? <FaPause /> : <FaPlay />} {isPlaying ? 'Pause' : 'Play'}
                </button>
            </PlaybackControls>

            {/* Context Menu for Adding Items */}
            <ContextMenuTrigger id="timeline-contextmenu" holdToDisplay={1000}>
                <TransformWrapper
                    initialScale={1}
                    minScale={0.5}
                    maxScale={5}
                    scale={transformScale}
                    onZoomChange={e => setTransformScale(e.state.scale)}
                >
                    {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                        <>
                            <TransformComponent>
                                <div
                                    style={{
                                        position: 'relative',
                                        width: `${TOTAL_DURATION * 2}px`,
                                        height: '100%'
                                    }}
                                >
                                    {renderTimeMarkers()}
                                    {renderItems()}
                                </div>
                            </TransformComponent>
                        </>
                    )}
                </TransformWrapper>
            </ContextMenuTrigger>

            <ContextMenu id="timeline-contextmenu">
                <MenuItem onClick={handleAddItem}>
                    <FaPlus style={{marginRight: '8px'}} /> Add Item
                </MenuItem>
            </ContextMenu>

            <ReactTooltip />
        </TimelineContainer>
    );
};

export default VideoTimeline;
