/*
    Prerenderer will take a text, and a style function (which takes ctx as an argument) and return a promise that resolves to a bitmap of the text prerendered with canvas
*/

const pixelateImage = image => {
    const pixelSize = 1;
    return new Promise(resolve => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0);

        const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imgData.data;

        for (let y = 0; y < canvas.height; y += pixelSize) {
            for (let x = 0; x < canvas.width; x += pixelSize) {
                const offset = (y * canvas.width + x) * 4;
                const r = data[offset];
                const g = data[offset + 1];
                const b = data[offset + 2];
                const a = data[offset + 3];

                for (let y2 = 0; y2 < pixelSize; y2++) {
                    for (let x2 = 0; x2 < pixelSize; x2++) {
                        const offset2 = ((y + y2) * canvas.width + (x + x2)) * 4;
                        data[offset2] = r;
                        data[offset2 + 1] = g;
                        data[offset2 + 2] = b;
                        data[offset2 + 3] = a;
                    }
                }
            }
        }

        ctx.putImageData(imgData, 0, 0);

        // Resolve bitmap
        resolve(createImageBitmap(canvas));
    });
};

const debug = false;

let debuggerEl;

if (debug) {
    debuggerEl = document.createElement('div');
    debuggerEl.style.position = 'fixed';
    debuggerEl.style.bottom = '0';
    debuggerEl.style.right = '0';
    debuggerEl.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    debuggerEl.style.color = 'white';
    debuggerEl.style.padding = '10px';
    debuggerEl.style.width = '500px';
    debuggerEl.style.height = 'auto';
    debuggerEl.className = 'debugger';
    document.body.appendChild(debuggerEl);
}

const createImageBitmap = canvas => {
    return new Promise(resolve => {
        const img = new Image();
        img.onload = () => {
            resolve(img);
        };
        img.src = canvas.toDataURL();
    });
};

class Prerenderer {
    constructor(width, height) {
        this.cache = new Map();
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.canvas.width = width;
        this.canvas.height = height;
        this.bounds = {};
    }

    async prerender(text, style, settings, prevLines, nextLines) {
        const key = text + style.toString() + JSON.stringify(settings);
        if (this.cache.has(key)) {
            return this.cache.get(key);
        }

        const canvas = this.canvas;
        const ctx = this.ctx;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();

        ctx.translate(canvas.width / 2, canvas.height / 2);

        ctx.font = '20px Arial';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';

        style && style(ctx);
        // Center on 1080x1080 canvas
        // Split the text by "\n" or "more than 5 words per line"
        // const words = text.replace('\n').split(' ');
        const words = text.split(' ');
        let lines = [];
        let line = '';
        words.forEach(word => {
            if (line.length + word.length < 45 || line.indexOf('\n') !== -1) {
                line += word + ' ';
            } else {
                lines.push(line);
                line = word + ' ';
            }
        });
        lines.push(line);

        // Loop through the lines and split them further by \n
        const splitLines = [];
        lines.forEach(line => {
            const split = line.split('\n');
            splitLines.push(...split);
        });
        lines = splitLines;

        const lineHeight = ctx.measureText('M').width * (window.lineHeight || 2.5);

        lines.forEach((line, index) => {
            const y = index * lineHeight - ((lines.length - 1) * lineHeight) / 2;
            ctx.fillText(line, 0, y);
        });

        ctx.restore();

        // In main context not worker
        let bitmap = await createImageBitmap(canvas);

        // bitmap = await pixelateImage(bitmap, 2);

        this.cache.set(key, bitmap);

        // Set the bounds of the text (not the canvas since it will have padding)
        // Just height
        this.bounds[key] = {
            lineCount: lines.length,
            lineHeight: lineHeight
        };

        // Also prerender the same text with a green border (for hit detection) with the key + 'hit'
        /*ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();

        // Draw it as the same text but with a green border rect
        ctx.translate((canvas.width / 2), (canvas.height / 2));
        style && style(ctx);

        let lineHeight = ctx.measureText('M').width * 2.5;

        lines.forEach((line, index) => {
            lineHeight = ctx.measureText('M').width * 2.5;
            const y = (index * lineHeight) - ((lines.length - 1) * lineHeight) / 2;
            ctx.fillText(line, 0, y);
        });

        // Find the bounding box of the text
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;
        const textHeight = lines.length * lineHeight;

        // Draw a green border around the text
        ctx.strokeStyle = 'green';
        ctx.lineWidth = 2;
        ctx.strokeRect(-textWidth / 2, -textHeight / 2, textWidth, textHeight);

        this.bounds[text + style.toString()] = {
            x: -textWidth / 2,
            y: -textHeight / 2,
            width: textWidth,
            height: textHeight
        };

        ctx.restore();

        const hitBitmap = await createImageBitmap(canvas);

        this.cache.set(key + 'hit', hitBitmap);*/

        // For debug add image to body
        /*if (debug) {
            debuggerEl.innerHTML = '';
            setTimeout(() => {
                // Clone the bitmap
                const img = new Image();
                img.src = bitmap.src;
                img.style.width = '100%';
                img.style.height = 'auto';
                debuggerEl.appendChild(img);

                // Append the hit bitmap
                const hitImg = new Image();
                hitImg.src = hitBitmap.src;
                hitImg.style.width = '100%';
                hitImg.style.height = 'auto';
                debuggerEl.appendChild(hitImg);
            }, 500);
        }*/

        return bitmap;
    }

    getBounds(text, style, settings) {
        const key = text + style.toString() + JSON.stringify(settings);
        return this.bounds[key];
    }
}

const prerenderers = new Map();
const hasPrerendered = new Map();

const subtitles = async ({
    hitCoordinates,
    fps,
    currentTime,
    x,
    y,
    rotate,
    scale,
    opacity,
    text,
    srtRender,
    renderFunction,
    ctx,
    style,
    canvasWidth,
    canvasHeight,
    settings
}) => {
    const relevantSettings = [
        'font',
        'fontRenew',
        'fontFamily',
        'fontSize',
        'fontWeight',
        'fontStyle',
        'lineHeight',
        'textAlign',
        'textBaseline',
        'direction',
        'fillStyle',
        'strokeStyle',
        'lineWidth',
        'lineDash',
        'lineDashOffset',
        'shadowColor',
        'shadowBlur',
        'shadowOffsetX',
        'shadowOffsetY',
        'globalAlpha',
        'globalCompositeOperation',
        'imageSmoothingEnabled',
        'imageSmoothingQuality',
        'filter'
    ];
    settings =
        settings &&
        Object.entries(settings).reduce((acc, [key, value]) => {
            if (relevantSettings.includes(key)) {
                acc[key] = value;
            }
            return acc;
        }, {});

    if (!prerenderers.has(srtRender)) {
        prerenderers.set(srtRender, new Prerenderer(canvasWidth, canvasHeight));
    }

    if (!text) {
        text = srtRender.getText(currentTime);
    }
    // Prerender future
    if (srtRender) {
        // Prerender all lines
        const allLines = srtRender.getLines();

        if (allLines && allLines.length > 0 && !hasPrerendered.get(JSON.stringify([style, settings, allLines]))) {
            hasPrerendered.set(JSON.stringify([style, settings, allLines]), true);

            (async () => {
                const prerenderPromises = [];
                allLines.reduce(async (prev, line) => {
                    await new Promise(resolve => setTimeout(() => requestAnimationFrame(resolve), 1000 / fps));
                    return prev.then(() => {
                        console.log('Prerendering', line);
                        return prerenderers.get(srtRender).prerender(line, style, settings);
                    });
                }, Promise.resolve());
            })();
        }
    }
    //  Draw hit border (green solid line around text) if hitCoordinates are withi
    const prerendered = await prerenderers.get(srtRender).prerender(text, style, settings);

    ctx.globalAlpha = opacity;

    ctx.save();
    ctx.translate(canvasWidth / 2 + x, canvasHeight / 2 + y); // + (canvasHeight / 4));
    ctx.rotate((rotate * Math.PI) / 180);

    ctx.scale(scale, scale);

    ctx.drawImage(prerendered, -prerendered.width / 2, -prerendered.height / 2);

    ctx.restore();

    ctx.globalAlpha = 1;

    // Return the bounding box of the text
    return prerenderers.get(srtRender).getBounds(text, style, settings);
};

export default subtitles;
