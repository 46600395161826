// src/components/timeline/PlaybackIndicator.js

import React from "react";
import styled from "styled-components";

const Indicator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: red;
  z-index: 10;
`;

const PlaybackIndicator = React.forwardRef((props, ref) => {
  return <Indicator ref={ref} />;
});

export default PlaybackIndicator;
