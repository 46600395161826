import React from 'react';
import styled, {css} from 'styled-components';
import {motion} from 'framer-motion';

const Row = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;

        margin: 20px -10px;

        /*div {
            margin: 20px;
        }*/
    }

    // <Row direction="column" gap={2}
    ${props =>
        typeof props.direction === 'string' &&
        css`
            flex-direction: ${props.direction} !important;
        `}

    ${props =>
        typeof props.gap === 'number' &&
        css`
            > * {
                margin: ${props.gap}px;
            }
        `}
`;

const Column = styled(motion.div)`
    flex: 1;
    // Break long lines
    pre {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    margin: 10px;

    // sm prop contains styles to be applied on small screens
    ${props =>
        props.sm &&
        css`
            @media (max-width: 768px) {
                ${props.sm}
            }
        `}
`;

export {Row, Column};
