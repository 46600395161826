import React, {forwardRef, useRef, useEffect, useState, useMemo} from 'react';
import {motion, useScroll, useMotionValueEvent} from 'framer-motion';

const AlwaysVisibleCanvas = forwardRef(({style, ...props}, ref) => {
    const dummyRef = useRef();
    const {scrollY} = useScroll();

    // Watch for when the scroll top reaches the top of the canvas
    // And then set the canvas via ref to position fixed  with set width and height
    // And restore it when the scroll top is below the canvas
    useMotionValueEvent(scrollY, 'change', latest => {
        const canvas = ref.current;
        if (canvas) {
            if (latest > dummyRef.current.getBoundingClientRect().top - 20 && canvas.style.position !== 'fixed') {
                const rect = canvas.getBoundingClientRect();

                dummyRef.current.style.height = rect.height + 'px';
                dummyRef.current.style.width = rect.width + 'px';

                canvas.style.top = '20px';
                canvas.style.left = rect.left + 'px';
                canvas.style.width = rect.width + 'px';
                canvas.style.height = rect.height + 'px';
                canvas.style.minWidth = '0';
                canvas.style.minHeight = '0';
                canvas.style.maxWidth = '100%';
                canvas.style.maxHeight = '50vh';
                canvas.style.position = 'fixed';

                // Set the canvas to be on top of everything
                canvas.style.zIndex = 1000;
            } else if (latest <= dummyRef.current.getBoundingClientRect().top && canvas.style.position !== 'static') {
                canvas.style.position = 'static';
                canvas.style.top = 'auto';
                canvas.style.left = 'auto';
                canvas.style.width = 'auto';
                canvas.style.height = 'auto';
                canvas.style.minWidth = '100%';
                canvas.style.minHeight = '100%';
                dummyRef.current.style.height = '0px';
                dummyRef.current.style.width = '0px';
            }

            canvas.style.maxWidth = '100%';
            canvas.style.maxHeight = '100%';
        }
    });

    const canvas = useMemo(() => {
        return (
            <>
                <div
                    ref={dummyRef}
                    key="dummy"
                    style={{
                        width: '0px',
                        height: '0px',
                        position: 'relative',
                        zIndex: -1
                    }}
                />
                <motion.canvas
                    ref={ref}
                    key="canvas"
                    style={{
                        ...(style || {})
                    }}
                    {...props}
                />
            </>
        );
    }, [style, props]);

    return canvas;
});

export default AlwaysVisibleCanvas;
